import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { MyAccountNavigationProps } from "~/bff/components/MyAccountNavigation";
import { Account } from "~/bff/types/Account";
import { STATES } from "~/components/account-page/types";
import { LOCALES } from "~/constants/i18n";
import { TRIAL_STORE_USER_GROUP } from "~/constants/user-groups";
import { useLoadAccountLazyQuery } from "~/hooks/use-load-account-lazy-query";
import { useLoginLogout } from "~/hooks/use-login-logout";
import { useToken } from "~/hooks/use-token";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";

import { AuthContext } from "./context";

export type AuthProviderProps = PropsWithChildren;

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const { locale } = useParams<GlobalPathParams>();

  const [currentAccount, setCurrentAccount] = React.useState<Account | null>(null);

  const [consents, setConsents] = useState<Map<string, null | boolean>>(
    new Map<LOCALES, null | boolean>(),
  );

  const [selectedState, setSelectedState] = useState<STATES>(STATES.ACCOUNT);
  const [accountNavigation, setAccountNavigation] =
    useState<MyAccountNavigationProps>({});

  const updateCurrentAccount = useCallback(
    (account: Account | null) => {
      if (account) {
        setCurrentAccount((prev) =>
          prev ? { ...prev, ...account } : { ...account },
        );
      } else {
        startLogout(false, true);
      }
    },
    [setCurrentAccount], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const resetCurrentAccount = useCallback(() => {
    setCurrentAccount(null);
    consents.clear();
    setConsents(consents);
  }, [setCurrentAccount, setConsents, consents]);

  const isUserAuthenticated = useIsAuthenticated();
  const { extractToken } = useToken(locale);
  const { startLogout } = useLoginLogout(locale);
  const config = useAzureConfigurator(locale);
  const { instance } = useMsal();
  const userData = instance?.getAllAccounts()?.[0];
  const userGroups = useMemo(() => {
    return (userData?.idTokenClaims?.groups as string[]) || [];
  }, [userData]);

  const isMyAccountEnabled = config?.featureFlags?.myAccount?.enabled;

  const getAccountErrorHandler = useCallback(() => {
    startLogout();
  }, [startLogout]);

  const updateLegalConsents = useCallback(
    (account: Account | null, locale: string) => {
      updateCurrentAccount(account);
      consents.set(locale, true);
      setConsents(consents);
    },
    [consents, updateCurrentAccount],
  );

  const [getAccountData] = useLoadAccountLazyQuery(
    locale,
    async (account: Account | null) => {
      updateLegalConsents(account, locale);
    },
    getAccountErrorHandler,
  );

  const checkLegalConsent = useCallback(async () => {
    if (consents.has(locale) && consents.get(locale)) {
      return;
    }
    try {
      if (isUserAuthenticated) {
        const token = await extractToken();
        getAccountData({
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          variables: {
            locale: locale,
          },
        });
      }
    } catch (e) {
      startLogout();
    }
  }, [
    extractToken,
    getAccountData,
    locale,
    consents,
    startLogout,
    isUserAuthenticated,
  ]);

  useEffect(() => {
    if (isUserAuthenticated && isMyAccountEnabled) {
      checkLegalConsent();
    }
  }, [locale, isUserAuthenticated, isMyAccountEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isUserAuthenticated && !isMyAccountEnabled) {
      startLogout(false);
    }
  }, [isMyAccountEnabled, isUserAuthenticated, startLogout]);

  return (
    <AuthContext.Provider
      value={{
        account: currentAccount ?? undefined,
        isMemberOfTrialStoreGroup: userGroups.includes(TRIAL_STORE_USER_GROUP),
        updateAccount: (account: Account | null) => updateCurrentAccount(account),
        resetAccount: () => resetCurrentAccount(),
        selectedState,
        setSelectedState,
        accountNavigation,
        setAccountNavigation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
