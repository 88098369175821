"use client";

import React, { FC, PropsWithChildren } from "react";

import { CorrelationIdContext } from "./context";
import { CorrelationIdContextValue } from "./types";

export type CorrelationIdContextProviderProps =
  PropsWithChildren<CorrelationIdContextValue>;

export const CorrelationIdContextProvider: FC<CorrelationIdContextProviderProps> = ({
  children,
  correlationId,
}) => {
  return (
    <CorrelationIdContext.Provider value={{ correlationId }}>
      {children}
    </CorrelationIdContext.Provider>
  );
};
