import { gql } from "@apollo/client";

export const GLOBAL_LAYOUT_FIELDS = gql`
  fragment globalLayoutFields on GlobalLayout {
    component
    _meta {
      deliveryId
    }
    props {
      informationBanner {
        component
        props {
          link {
            label
            url
          }
          text
          newText
          textColor
          backgroundColor
        }
      }
      header {
        component
        _meta {
          deliveryId
        }
        children {
          ... on HeaderPrimaryNavLinks {
            component
            children {
              component
              props {
                title
              }
              children {
                component
                props {
                  link {
                    label
                    url
                  }
                }
              }
            }
          }
          ... on HeaderSecondaryNavLinks {
            component
            children {
              component
              props {
                link {
                  label
                  url
                }
                image {
                  alt
                  title
                  source {
                    id
                    name
                    endpoint
                    defaultHost
                  }
                }
              }
            }
          }
          ... on HeaderLogoImage {
            component
            props {
              image {
                alt
                title
                source {
                  id
                  name
                  endpoint
                  defaultHost
                }
              }
            }
          }
        }
        props {
          storeLocatorLinkLabel
          translations {
            viewAll
            accountName
          }
          searchPanel {
            _meta {
              deliveryId
            }
            component
            props {
              translations {
                lastSearchesSectionTitle
                popularSectionTitle
                storeLocatorLabel
                searchText
                clearAllText
              }
              popularSearch
              needHelp {
                label
                url
              }
            }
            children {
              _meta {
                name
                schema
                deliveryId
                deliveryKey
                hierarchy {
                  root
                  parentId
                }
              }
              component
              props {
                navMenuImage {
                  alt
                  title
                  source {
                    _meta {
                      schema
                    }
                    id
                    name
                    endpoint
                    defaultHost
                  }
                }
                carouselImage {
                  alt
                  title
                  source {
                    _meta {
                      schema
                    }
                    id
                    name
                    endpoint
                    defaultHost
                  }
                }
                specialLinks {
                  _meta {
                    name
                    schema
                    deliveryId
                    deliveryKey
                  }
                  component
                  children {
                    _meta {
                      name
                      schema
                      deliveryId
                      deliveryKey
                    }
                    component
                    props {
                      image {
                        alt
                        title
                        source {
                          _meta {
                            schema
                          }
                          id
                          name
                          defaultHost
                          endpoint
                        }
                      }
                      name
                      link {
                        label
                        url
                      }
                    }
                  }
                }
                bannerArray {
                  _meta {
                    name
                    schema
                    deliveryId
                    deliveryKey
                  }
                  component
                }
                isGlobalNav
                id
                title
                description
                order
                urlSlug
              }
              children {
                _meta {
                  name
                  schema
                  deliveryId
                  deliveryKey
                  hierarchy {
                    root
                    parentId
                  }
                }
                component
                props {
                  navMenuImage {
                    alt
                    title
                    source {
                      _meta {
                        schema
                      }
                      id
                      name
                      endpoint
                      defaultHost
                    }
                  }
                  carouselImage {
                    alt
                    title
                    source {
                      _meta {
                        schema
                      }
                      id
                      name
                      endpoint
                      defaultHost
                    }
                  }
                  specialLinks {
                    _meta {
                      name
                      schema
                      deliveryId
                      deliveryKey
                    }
                    component
                  }
                  bannerArray {
                    _meta {
                      name
                      schema
                      deliveryId
                      deliveryKey
                    }
                    component
                    children {
                      _meta {
                        name
                        schema
                        deliveryId
                        deliveryKey
                      }
                      component
                      props {
                        image {
                          alt
                          title
                          source {
                            _meta {
                              schema
                            }
                            id
                            name
                            endpoint
                            defaultHost
                          }
                        }
                        link {
                          label
                          url
                        }
                      }
                    }
                  }
                  isGlobalNav
                  id
                  title
                  description
                  order
                  urlSlug
                }
              }
            }
          }
        }
      }
      footer {
        component
        children {
          ... on FooterSocialLinks {
            component
            children {
              component
              props {
                icon {
                  component
                  props {
                    source {
                      id
                      name
                      endpoint
                      defaultHost
                    }
                    alt
                    title
                  }
                }
                link {
                  label
                  url
                }
              }
            }
          }
          ... on CountrySelector {
            component
            props {
              description
              title
            }
            children {
              component
              props {
                countryKey
                languageCode
                image {
                  alt
                  title
                  source {
                    id
                    name
                    endpoint
                    defaultHost
                  }
                }
                link {
                  label
                  url
                }
              }
            }
          }
          ... on FooterNav {
            component
            children {
              component
              props {
                title
              }
              children {
                component
                props {
                  link {
                    label
                    url
                  }
                  target
                }
              }
            }
          }
          ... on Copyright {
            component
            props {
              title
            }
          }
        }
      }
      productTags {
        productTags {
          description
          priorityNumber
          key
          title
          link {
            label
            url
          }
          textColor
          backgroundColor
        }
      }
      colorFilterPalette {
        component
        props {
          colors {
            colorName
            colorCode
            colorImage {
              alt
              title
              source {
                id
                name
                endpoint
                defaultHost
              }
            }
            checkMarkColor
          }
        }
      }
      dictionary {
        items {
          key
          value
        }
      }
      filtersMenu {
        component
        props {
          translations {
            filterTitle
            clearAllButtonText
            showItemsButtonText
            priceFilterOverText
            categoryFilterAllText
          }
        }
      }
      storeSelector {
        component
        props {
          title
          text
          translations {
            showInStockOnlyFilterTitle
            storeDetailsLinkName
            selectStoreButtonName
            errorText
            searchField {
              description
              placeholder
              myLocationButtonName
              clearButton
            }
            productStockAvailabilityStatusesTitles {
              inStockStatusTitle
              lowInStockStatusTitle
              outOfStockStatusTitle
              notRangedStatusTitle
            }
            storeSelectorProductStatusesTexts {
              inStockStatusText
              lowInStockStatusText
              outOfStockStatusText
              notRangedStatusText
            }
            storeDetailsProductStatusesTexts {
              inStockStatusText
              lowInStockStatusText
              outOfStockStatusText
              notRangedStatusText
            }
            oneSizeProduct {
              oneSizeStoreDetailsProductStatusesTexts {
                inStockStatusText
                lowInStockStatusText
                outOfStockStatusText
                notRangedStatusText
              }
              oneSizeStoreSelectorProductStatusesTexts {
                inStockStatusText
                lowInStockStatusText
                outOfStockStatusText
                notRangedStatusText
              }
            }
            stockAvailabilityStatuses {
              inStock
              lowInStock
              soldOutInStore
              notAvailableInStore
              notSoldInStore
            }
            backButtonText
            noFilterSearchResultsFound {
              title
              subtitle
            }
            clickAndCollectStatuses {
              inStock
              soldOut
              noTrial
            }
            showInClickCollectOnlyFilterTitle
            clickAndCollectStoresFilterTitle
            clickCollectTag
          }
        }
        _meta {
          name
          schema
          deliveryId
          deliveryKey
          translations {
            locales {
              id
              locale
              slug
            }
          }
        }
      }
      facetList {
        _meta {
          name
          schema
          deliveryId
          deliveryKey
          translations {
            locales {
              id
              locale
              slug
            }
          }
        }
        component
        props {
          items {
            facet
            displayName
          }
        }
      }
      passwordResetSuccessPopUp {
        component
        props {
          title
          description
          continueButtonName
        }
      }
      accessibility {
        items {
          key
          value
        }
      }
      countryNames {
        code
        name
      }
    }
  }
`;

export const GET_GLOBAL_LAYOUT = gql`
  query GlobalLayout($locale: String!, $pageSlug: String) {
    globalLayout(locale: $locale, pageSlug: $pageSlug) {
      ...globalLayoutFields
    }
  }
  ${GLOBAL_LAYOUT_FIELDS}
`;
