import { Theme, ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";

import { COLOR_BLACK, COLOR_FOCUSED, COLOR_WHITE } from "../colors";

export const getMuiSwitch = (theme: Theme): ComponentsOverrides["MuiSwitch"] => {
  const trackSize = {
    width: "36px",
    height: "20px",
    borderRadius: "20px",
    padding: 0,
  };

  const switchBaseSize = {
    padding: 0,
    width: trackSize.height,
    height: trackSize.height,
    borderRadius: "50%",
  };

  return {
    root: {
      ...trackSize,
      opacity: "1 !important",
      margin: theme.spacing(1),
      overflow: "visible",

      "&.focusVisible::before": {
        display: "none",
        content: "''",
        position: "absolute",
        top: -4,
        bottom: -4,
        left: -4,
        right: -4,
        border: `2px solid ${COLOR_FOCUSED}`,
        borderRadius: trackSize.borderRadius,
      },

      "&.focusVisible:focus-within::before": {
        display: "block",
      },
    },
    track: {
      ...trackSize,
      backgroundColor: COLOR_BLACK,
      opacity: 0.5,

      ".MuiSwitch-switchBase$checked + &": {
        opacity: 1,
      },
      ".MuiSwitch-root:hover &": {
        opacity: 0.75,
      },
      ".MuiSwitch-root:active .MuiSwitch-switchBase:not(.Mui-disabled) + &": {
        opacity: 1,
      },
      ".MuiSwitch-root:active .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + &":
        {
          opacity: 0.5,
        },
      ".MuiSwitch-switchBase.Mui-disabled + &": {
        opacity: 0.25,
      },
    },
    switchBase: {
      ...switchBaseSize,
      opacity: "1 !important",

      "&, &:hover, &:active": {
        backgroundColor: "transparent",
      },

      "&.Mui-checked": {
        "&, &:hover, &:active": {
          backgroundColor: "transparent",
        },

        transform: `translateX(calc(${trackSize.width} - 100%))`,
      },
    },
    input: {
      top: 0,
      left: 0,
      ...switchBaseSize,
    },
    thumb: {
      backgroundColor: COLOR_WHITE,
      boxShadow: "unset",
      width: 16,
      height: 16,
      border: "none",
    },
  };
};

export const getMuiSwitchDefaultProps = (): ComponentsProps["MuiSwitch"] => {
  return {
    color: "primary",
    focusVisibleClassName: "focusVisible",
    ...getTestAutomationProps("switch"),
  };
};
