import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { useIsAuthenticated } from "@azure/msal-react";
import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import {
  ShoppingListResponse,
  ShoppingListVariables,
} from "~/bff/transport/ShoppingList";
import { ShoppingList } from "~/bff/types/ShoppingList";
import { ShoppingListProduct } from "~/bff/types/ShoppingListProduct";
import { ErrorsList } from "~/constants/errorsMessages";
import { getCurrencyCodeByLocale } from "~/constants/i18n";
import { Routes } from "~/constants/request";
import { getApolloClient } from "~/graphql/client";
import {
  ADD_PRODUCT_TO_SHOPPING_LIST,
  GET_USER_SHOPPING_LIST,
  REMOVE_ITEM_FROM_SHOPPING_LIST,
  REPLACE_ITEM_IN_SHOPPING_LIST,
  MOVE_ITEM_FROM_SHOPPING_LIST_TO_SHOPPING_BAG,
  GET_USER_SHOPPING_LIST_SKU_ONLY,
  UNDO_REMOVE_ITEM_FROM_SHOPPING_LIST,
} from "~/graphql/queries/shoppingListQueries";
import { checkIsOneSizeProduct } from "~/helpers/check-is-one-size-product";
import { getLineItemIdentity } from "~/helpers/get-line-item-identity/index";
import { getSelectedOrPreferredStoreId } from "~/helpers/selected-store/get-selected-or-preferred-store-id";
import { useToken } from "~/hooks/use-token";
import { getChangeCartUrlEventData } from "~/lib/salesforce/evergage/helpers";
import { Evergage } from "~/lib/salesforce/evergage/index";
import { Logger } from "~/utils/logger";

import { AuthContext } from "../auth/context";

import { ShoppingListContext, ShoppingListContextData } from "./context";
import { DeletedProductType, HandleShoppingListOptions } from "./types";

export const ShoppingListProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { locale } = useParams<GlobalPathParams>();
  const pathName = usePathname();
  const isAuthenticated = useIsAuthenticated();
  const { extractToken } = useToken(locale);
  const [userShoppingList, setUserShoppingList] = useState<ShoppingList>({});
  const [shoppingListSkuOnly, setShoppingListSkuOnly] = useState<ShoppingList>({});
  const [userShoppingListId, setUserShoppingListId] = useState<string>("");
  const [isLimitPopupOpen, setIsLimitPopupOpen] = useState(false);
  const [deletedProducts, setDeletedProducts] = useState<DeletedProductType[]>([]);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const shoppingListSkuMap = useMemo<Map<string, ShoppingListProduct>>(() => {
    const map = new Map();

    if (shoppingListSkuOnly?.products?.length) {
      shoppingListSkuOnly?.products?.forEach((product) => {
        const isSizeSelected = checkIsOneSizeProduct(product?.sizeId ?? "")
          ? false
          : (product?.sizeIsSelected ?? "").toString();

        map.set(
          getLineItemIdentity(product?.sku ?? "", isSizeSelected.toString()),
          product,
        );
      });
    }
    return map;
  }, [shoppingListSkuOnly]);

  const client = useMemo(() => getApolloClient({ locale }), [locale]);

  const handleUpdateShoppingList = useCallback((shoppingList: ShoppingList) => {
    setUserShoppingList(shoppingList);
    setShoppingListSkuOnly(shoppingList);
    setUserShoppingListId(shoppingList?.id ?? "");
  }, []);

  const fetchAuthToken = useCallback(async () => {
    if (isAuthenticated) {
      try {
        return await extractToken();
      } catch (e) {
        Logger.getLogger().error(String(e));
        return null;
      }
    } else {
      return null;
    }
  }, [isAuthenticated, extractToken]);

  const [getShoppingList] = useLazyQuery<
    ShoppingListResponse,
    ShoppingListVariables & { storeId: string }
  >(GET_USER_SHOPPING_LIST, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ shoppingList }) => {
      if (shoppingList) {
        setIsLoading(false);
        handleUpdateShoppingList(shoppingList);
      }
    },
    onError: (e) => {
      setIsLoading(false);
      Logger.getLogger().error(e);
    },
  });

  const [getShoppingListSkuOnly] = useLazyQuery<
    ShoppingListResponse,
    ShoppingListVariables
  >(GET_USER_SHOPPING_LIST_SKU_ONLY, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ shoppingList }) => {
      if (shoppingList) {
        setShoppingListSkuOnly(shoppingList);
        setUserShoppingListId(shoppingList?.id ?? "");
      }
    },
    onError: (e) => Logger.getLogger().error(e),
  });

  const [addProductToShoppingList] = useMutation(ADD_PRODUCT_TO_SHOPPING_LIST, {
    notifyOnNetworkStatusChange: true,
    client,
    errorPolicy: "none",
    onCompleted: ({ addShoppingListItem: shoppingList }) => {
      if (shoppingList) {
        setShoppingListSkuOnly(shoppingList);
        setUserShoppingListId(shoppingList?.id);
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors?.[0]?.extensions?.code === ErrorsList.PRODUCTS_LIMIT_ERROR) {
        setIsLimitPopupOpen(true);
        throw new Error("Reached limit of products");
      }

      return Logger.getLogger().error(String(networkError));
    },
  });

  const [undoRemoveProductFromShoppngList] = useMutation(
    UNDO_REMOVE_ITEM_FROM_SHOPPING_LIST,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({ addShoppingListItem: shoppingList }) => {
        if (shoppingList) {
          handleUpdateShoppingList(shoppingList);
        }
      },
      onError: (e) => Logger.getLogger().error(e),
    },
  );

  const [removeProductFromShoppingList] = useMutation(
    REMOVE_ITEM_FROM_SHOPPING_LIST,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({ removeShoppingListItem: shoppingList }) => {
        if (shoppingList) {
          setShoppingListSkuOnly(shoppingList);
          setUserShoppingListId(shoppingList?.id);
        }
      },
      onError: (e) => Logger.getLogger().error(e),
    },
  );

  const [replaceItemInShoppingList] = useMutation(REPLACE_ITEM_IN_SHOPPING_LIST, {
    notifyOnNetworkStatusChange: true,
    client,
    onCompleted: ({ replaceShoppingListItem: shoppingList }) => {
      if (shoppingList) {
        handleUpdateShoppingList(shoppingList);
      }
    },
    onError: (e) => Logger.getLogger().error(e),
  });

  const [moveProductFromShoppingListToShoppingBag] = useMutation(
    MOVE_ITEM_FROM_SHOPPING_LIST_TO_SHOPPING_BAG,
    {
      notifyOnNetworkStatusChange: true,
      client,
      onCompleted: ({
        moveItemFromShoppingListToShoppingBag: { shoppingList, shoppingBag },
      }) => {
        if (shoppingList) {
          setIsLoading(false);
          handleUpdateShoppingList(shoppingList);
        }
        if (shoppingBag) {
          Evergage.sendEvent(locale, getChangeCartUrlEventData(shoppingBag, locale));
        }
      },
      onError: (e) => {
        setIsLoading(false);
        Logger.getLogger().error(e);
      },
    },
  );

  const handleMoveItemFromShoppingListToShoppingBag = useCallback(
    async (options: HandleShoppingListOptions) => {
      const token = await fetchAuthToken();
      const storeId: string =
        getSelectedOrPreferredStoreId(authContext?.account) ?? "";
      setIsLoading(true);

      return moveProductFromShoppingListToShoppingBag({
        variables: {
          locale,
          storeId,
          currencyCode: getCurrencyCodeByLocale(locale),
          quantity: 1,
          collectionStoreId: storeId,
          ...options,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [
      fetchAuthToken,
      locale,
      moveProductFromShoppingListToShoppingBag,
      authContext?.account,
    ],
  );

  const handleAddProductToShoppingList = useCallback(
    async (options: HandleShoppingListOptions) => {
      const token = await fetchAuthToken();

      return addProductToShoppingList({
        variables: {
          locale,
          currencyCode: getCurrencyCodeByLocale(locale),
          ...options,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [addProductToShoppingList, fetchAuthToken, locale],
  );

  const handleUndoRemoveProductFromShopingList = useCallback(
    async (options: HandleShoppingListOptions) => {
      const token = await fetchAuthToken();
      const storeId: string =
        getSelectedOrPreferredStoreId(authContext?.account) ?? "";

      return undoRemoveProductFromShoppngList({
        variables: {
          locale,
          storeId,
          currencyCode: getCurrencyCodeByLocale(locale),
          ...options,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [authContext?.account, fetchAuthToken, locale, undoRemoveProductFromShoppngList],
  );

  const handleRemoveProductFromShoppingList = useCallback(
    async (options: HandleShoppingListOptions) => {
      const token = await fetchAuthToken();
      const storeId: string =
        getSelectedOrPreferredStoreId(authContext?.account) ?? "";

      return removeProductFromShoppingList({
        variables: {
          locale,
          storeId,
          currencyCode: getCurrencyCodeByLocale(locale),
          ...options,
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [fetchAuthToken, locale, removeProductFromShoppingList, authContext?.account],
  );

  const handleClosePopup = useCallback(() => {
    setIsLimitPopupOpen(false);
  }, []);

  const handleReplaceShoppingListItem = useCallback(
    async (options: HandleShoppingListOptions) => {
      const token = await fetchAuthToken();
      const storeId: string =
        getSelectedOrPreferredStoreId(authContext?.account) ?? "";

      return replaceItemInShoppingList({
        variables: {
          locale,
          storeId,
          ...options,
          currencyCode: getCurrencyCodeByLocale(locale),
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [fetchAuthToken, locale, replaceItemInShoppingList, authContext?.account],
  );

  const handleGetShoppingList = useCallback(
    async (currentStoreId?: string) => {
      const token = await fetchAuthToken();
      const storeId =
        (currentStoreId || getSelectedOrPreferredStoreId(authContext?.account)) ??
        "";
      setIsLoading(true);

      getShoppingList({
        variables: {
          locale,
          storeId,
          currencyCode: getCurrencyCodeByLocale(locale),
        },
        ...(token && {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }),
      });
    },
    [fetchAuthToken, getShoppingList, locale, authContext?.account],
  );

  const handleGetShoppingListSkuOnly = useCallback(async () => {
    const token = await fetchAuthToken();

    getShoppingListSkuOnly({
      variables: {
        locale,
        currencyCode: getCurrencyCodeByLocale(locale),
      },
      ...(token && {
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      }),
    });
  }, [fetchAuthToken, getShoppingListSkuOnly, locale]);

  const handleAddProductToDeleted = useCallback(
    (product: DeletedProductType) => {
      setDeletedProducts([...deletedProducts, product]);
    },
    [deletedProducts],
  );

  const handleRemoveProductFromDeleted = useCallback(
    (sku: string) => {
      setDeletedProducts(deletedProducts.filter((product) => product?.sku !== sku));
    },
    [deletedProducts],
  );

  const clearDeletedProducts = useCallback(() => {
    setDeletedProducts([]);
  }, []);

  const handleClearUpShoppingListsData = useCallback(() => {
    setShoppingListSkuOnly({});
    setUserShoppingList({});
    setUserShoppingListId("");
  }, []);

  useEffect(() => {
    if (pathName?.includes(Routes.SHOPPING_LIST)) {
      handleClearUpShoppingListsData();
      handleGetShoppingList();
    }
  }, [
    isAuthenticated,
    handleGetShoppingList,
    pathName,
    handleClearUpShoppingListsData,
  ]);

  useEffect(() => {
    if (
      !pathName?.includes(Routes.LOGOUT) &&
      !pathName?.includes(Routes.SHOPPING_LIST)
    ) {
      handleGetShoppingListSkuOnly();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const shoppingList = useMemo(
    () =>
      ({
        isLoading,
        userShoppingList,
        shoppingListSkuMap,
        handleGetShoppingList,
        handleAddProductToShoppingList,
        handleRemoveProductFromShoppingList,
        handleUpdateShoppingList,
        handleReplaceShoppingListItem,
        handleAddProductToDeleted,
        handleRemoveProductFromDeleted,
        handleMoveItemFromShoppingListToShoppingBag,
        handleUndoRemoveProductFromShopingList,
        deletedProducts,
        clearDeletedProducts,
        isLimitPopupOpen,
        handleClosePopup,
        userShoppingListId,
      }) as unknown as ShoppingListContextData,
    [
      handleAddProductToShoppingList,
      handleGetShoppingList,
      handleRemoveProductFromShoppingList,
      handleUpdateShoppingList,
      handleReplaceShoppingListItem,
      handleAddProductToDeleted,
      handleRemoveProductFromDeleted,
      handleMoveItemFromShoppingListToShoppingBag,
      handleUndoRemoveProductFromShopingList,
      deletedProducts,
      clearDeletedProducts,
      userShoppingList,
      shoppingListSkuMap,
      isLimitPopupOpen,
      handleClosePopup,
      userShoppingListId,
      isLoading,
    ],
  );

  return (
    <ShoppingListContext.Provider value={shoppingList}>
      {children}
    </ShoppingListContext.Provider>
  );
};
