import {
  Theme,
  BreakpointsOptions,
  ComponentsOverrides,
  ComponentsProps,
} from "@mui/material/styles";

export const breakpointOptions: BreakpointsOptions = {
  keys: ["xs", "sm", "md", "lg", "xl"],
  values: { xs: 320, sm: 560, md: 1025, lg: 1153, xl: 1680 },
};

export const getMuiGrid = (theme: Theme): ComponentsOverrides["MuiGrid"] => {
  return {
    container: {
      height: `calc(100% + ${theme.spacing(2)})`,
      width: `calc(100% + ${theme.spacing(2)})`,
      [theme.breakpoints.up("sm")]: {
        height: `calc(100% + ${theme.spacing(3)})`,
        width: `calc(100% + ${theme.spacing(3)})`,
      },
    },
  };
};

export const getMuiGridDefaultProps = (): ComponentsProps["MuiGrid"] => {
  return {
    spacing: [2, 3],
  };
};
