import { Theme, ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { COLOR_BLACK } from "~/theme/colors";

export const getMuiInputLabel = (
  theme: Theme,
): ComponentsOverrides["MuiInputLabel"] => ({
  root: {
    color: COLOR_BLACK,
  },
  animated: {
    transition: "none",
  },
  outlined: {
    "&.MuiInputLabel-shrink": {
      position: "inherit",
      transform: "none",
      paddingBottom: 4,

      // todo: We need to reuse styles defined in Typography for body2.
      fontFamily: "Arial",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.02em",
    },
  },
});

export const getMuiInputLabelDefaultProps = (): ComponentsProps["MuiInputLabel"] => {
  return {
    shrink: true,
    disableAnimation: true,
  };
};
