import React, { VFC } from "react";

import { IconProps } from "../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Checkbox24: VFC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path d="M3 21V3H21V21H3Z" stroke="currentColor" strokeWidth="2" />
    </BaseSVGIcon>
  );
};

export const CheckboxChecked24: VFC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path d="M22 2H2V22H22V2Z" fill="currentColor" />
      <path
        d="M17 8L10 15L7 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const CheckboxIndeterminate24: VFC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path d="M22 2H2V22H22V2Z" fill="currentColor" />
      <path
        d="M16 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};
