import { ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { COLOR_GREYSCALE_BLACK_25 } from "~/theme/colors";

export const getMuiBackdrop = (): ComponentsOverrides["MuiBackdrop"] => {
  return {
    root: {
      backgroundColor: COLOR_GREYSCALE_BLACK_25,
    },
  };
};

export const getMuiBackdropDefaultProps = (): ComponentsProps["MuiBackdrop"] =>
  getTestAutomationProps("backdrop") as ComponentsProps["MuiBackdrop"];
