import React from "react";

import Box from "@mui/material/Box";
import { Theme, ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Down24 } from "~/theme/icons/components/down";

export const getMuiAccordionSummary = (
  theme: Theme,
): ComponentsOverrides["MuiAccordionSummary"] => {
  return {
    root: {
      padding: 0,
      paddingRight: theme.spacing(0.625),
      textTransform: "uppercase",
    },
  };
};

const ExpandIcon: React.FC = () => {
  return (
    <Box sx={{ height: 24, width: 24, color: "black" }}>
      <Down24 />
    </Box>
  );
};

export const getMuiAccordionSummaryDefaultProps =
  (): ComponentsProps["MuiAccordionSummary"] => {
    return {
      expandIcon: <ExpandIcon />,
      ...getTestAutomationProps("summary"),
    };
  };
