import React from "react";

import { ShoppingListResponse } from "~/bff/transport/ShoppingList";
import { Product } from "~/bff/types/Product";
import { ShoppingList } from "~/bff/types/ShoppingList";
import { ShoppingListProduct } from "~/bff/types/ShoppingListProduct";
import { Optional } from "~/types/general.types";

import {
  DeletedProductType,
  HandleShoppingListOptions,
  MoveProductFromShoppingListToShoppingBagResponse,
} from "./types";

export type ShoppingListContextData =
  | {
      userShoppingList?: ShoppingList;
      shoppingListSkuMap: Map<string, ShoppingListProduct | null>;
      handleGetShoppingList: (id?: string) => Promise<void>;
      handleAddProductToDeleted?: (product: Product) => void;
      handleRemoveProductFromDeleted?: (product: string) => void;

      deletedProducts?: DeletedProductType[];
      handleAddProductToShoppingList?: (
        options: HandleShoppingListOptions,
        needToShowNotification?: boolean,
      ) => Promise<ShoppingListResponse>;
      handleUndoRemoveProductFromShopingList?: (
        options: HandleShoppingListOptions,
      ) => Promise<ShoppingListResponse>;

      handleReplaceShoppingListItem?: (
        options: HandleShoppingListOptions,
      ) => Promise<ShoppingListResponse>;
      handleMoveItemFromShoppingListToShoppingBag?: (
        options: HandleShoppingListOptions,
      ) => Promise<MoveProductFromShoppingListToShoppingBagResponse>;
      handleRemoveProductFromShoppingList?: (
        options: HandleShoppingListOptions,
      ) => Promise<ShoppingListResponse>;
      clearDeletedProducts: () => void;
      isLimitPopupOpen?: boolean;
      handleClosePopup?: () => void;
      handleUpdateShoppingList?: (shoppingList: ShoppingList) => void;
      userShoppingListId?: string;
      isLoading?: boolean;
    }
  | undefined;

export const ShoppingListContext =
  React.createContext<Optional<ShoppingListContextData>>(undefined);
