import React from "react";

import Box from "@mui/material/Box";
import { ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { Down24 } from "../icons/components/down";

const SelectArrow: React.FC = () => {
  return (
    <Box
      sx={(theme) => {
        return {
          height: 24,
          width: 24,
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(-4),
          pointerEvents: "none",
        };
      }}
    >
      <Down24 />
    </Box>
  );
};

export const getMuiSelect = (): ComponentsOverrides["MuiSelect"] => ({
  select: {
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: "inherit",
    lineHeight: "24px",
  },
  outlined: {
    borderRadius: 0,
  },
});

export const getMuiSelectDefaultProps = (): ComponentsProps["MuiSelect"] => {
  return {
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
    IconComponent: SelectArrow,
  };
};
