import { ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { COLOR_GREYSCALE_BLACK_8 } from "~/theme/colors";

export const getMuiAccordion = (): ComponentsOverrides["MuiAccordion"] => {
  return {
    root: {
      background: "none",
      boxShadow: "none",
      "&.Mui-expanded": {
        margin: 0,
        "&:before": {
          opacity: 1,
        },
      },
      "&.Mui-expanded + &": {
        "&:before": {
          display: "block",
        },
      },
      "&:first-of-type": {
        "&:before": {
          display: "block",
        },
      },
      "&:before": {
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
      },
    },
  };
};

export const getMuiAccordionDefaultProps = (): ComponentsProps["MuiAccordion"] =>
  getTestAutomationProps("accordion") as ComponentsProps["MuiAccordion"];
