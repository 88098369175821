"use client";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Cookies from "js-cookie";
import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { PREVIOUS_CURRENT_LOCALES, PREVIOUS_PATH } from "~/constants/local-storage";
import { EMPTY_ROUTE_HISTORY_MASK } from "~/context/router-history/constants";

import { RouterHistoryContext } from "./context";

export const RouterHistoryProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { locale } = useParams<GlobalPathParams>();

  const path = usePathname();
  const localeRef = useRef(locale);
  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    const previousPaths = localStorage.getItem(PREVIOUS_PATH)?.split(",");

    const currentPath = location?.href;
    if (previousPaths?.length) {
      const lastPath = previousPaths[1];
      const isSamePath = lastPath === location?.href;
      const newHistory = isSamePath
        ? `${EMPTY_ROUTE_HISTORY_MASK},${lastPath}`
        : `${lastPath},${currentPath}`;

      localStorage.setItem(PREVIOUS_PATH, newHistory);
      Cookies.set(PREVIOUS_PATH, newHistory);
      setPreviousPath(isSamePath ? EMPTY_ROUTE_HISTORY_MASK : lastPath);
    } else {
      const newHistory = `${EMPTY_ROUTE_HISTORY_MASK},${currentPath}`;
      localStorage.setItem(PREVIOUS_PATH, newHistory);
      Cookies.set(PREVIOUS_PATH, newHistory);
      setPreviousPath(EMPTY_ROUTE_HISTORY_MASK);
    }
  }, [path]);

  useEffect(() => {
    if (!localStorage.getItem(PREVIOUS_CURRENT_LOCALES)) {
      localStorage.setItem(PREVIOUS_CURRENT_LOCALES, `${locale},${locale}`);
    }

    const locales = localStorage.getItem(PREVIOUS_CURRENT_LOCALES)?.split(",");

    if (locales && (locale !== localeRef.current || locale !== locales[1])) {
      localStorage.setItem(PREVIOUS_CURRENT_LOCALES, `${locales[1]},${locale}`);
      localeRef.current = locale;
    } else {
      localStorage.setItem(PREVIOUS_CURRENT_LOCALES, `${locale},${locale}`);
    }
  }, [locale]);

  const routerHistory = useMemo(() => ({ previousPath }), [previousPath]);

  return (
    <RouterHistoryContext.Provider value={routerHistory}>
      {children}
    </RouterHistoryContext.Provider>
  );
};
