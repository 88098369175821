import { gql } from "@apollo/client";

import { GLOBAL_LAYOUT_FIELDS } from "~/graphql/queries/getGlobalLayout";

export const GET_GLOBAL_NAVIGATION_FIELDS = gql`
  fragment globalNavigationFields on NavItem {
    _meta {
      deliveryKey
      name
      deliveryId
      hierarchy {
        root
        parentId
      }
    }
    component
    props {
      id
      title
      order
      description
      navMenuImage {
        alt
        source {
          id
          name
          endpoint
          defaultHost
        }
      }
      carouselImage {
        alt
        source {
          id
          name
          endpoint
          defaultHost
        }
      }
      urlSlug
      url
    }
    children {
      _meta {
        deliveryKey
        name
        deliveryId
        hierarchy {
          root
          parentId
        }
      }
      component
      props {
        id
        title
        order
        description
        navMenuImage {
          alt
          source {
            id
            name
            endpoint
            defaultHost
          }
        }
        carouselImage {
          alt
          title
          source {
            id
            name
            endpoint
            defaultHost
          }
        }
        urlSlug
        url
        specialLinks {
          children {
            props {
              name
              link {
                label
                url
              }
              image {
                alt
                title
                source {
                  id
                  name
                  endpoint
                  defaultHost
                }
              }
            }
          }
        }
        bannerArray {
          children {
            props {
              image {
                alt
                title
                source {
                  id
                  name
                  endpoint
                  defaultHost
                }
              }
              link {
                label
                url
              }
            }
          }
        }
      }
      children {
        _meta {
          deliveryKey
          name
          deliveryId
          hierarchy {
            root
            parentId
          }
        }
        props {
          id
          title
          order
          description
          urlSlug
          url
          navMenuImage {
            alt
            source {
              id
              name
              endpoint
              defaultHost
            }
          }
          carouselImage {
            alt
            title
            source {
              id
              name
              endpoint
              defaultHost
            }
          }
        }
        component
        children {
          _meta {
            deliveryKey
            name
            deliveryId
            hierarchy {
              root
              parentId
            }
          }
          props {
            id
            title
            order
            description
            urlSlug
            url
            carouselImage {
              alt
              title
              source {
                id
                name
                endpoint
                defaultHost
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_GLOBAL_NAVIGATION = gql`
  query GlobalNavigation {
    globalNavigation {
      ...globalNavigationFields
    }
  }
  ${GET_GLOBAL_NAVIGATION_FIELDS}
`;

export const GET_GLOBAL_LAYOUT_AND_NAVIGATION = gql`
  query GlobalLayoutAndNavigationQuery($locale: String!) {
    layout: globalLayout(locale: $locale) {
      ...globalLayoutFields
    }

    navigation: globalNavigation(locale: $locale) {
      ...globalNavigationFields
    }
  }
  ${GLOBAL_LAYOUT_FIELDS}
  ${GET_GLOBAL_NAVIGATION_FIELDS}
`;
