import { createTheme, adaptV4Theme, Theme } from "@mui/material/styles";

import { getMuiAccordion, getMuiAccordionDefaultProps } from "./accordion";
import {
  getMuiAccordionDetails,
  getMuiAccordionDetailsDefaultProps,
} from "./accordion/details";
import {
  getMuiAccordionSummary,
  getMuiAccordionSummaryDefaultProps,
} from "./accordion/summary";
import { getMuiAppBar, getMuiAppBarDefaultProps } from "./app-bar";
import { getMuiBackdrop, getMuiBackdropDefaultProps } from "./backdrop";
import { getMuiBreadcrumbs, getMuiBreadcrumbsDefaultProps } from "./breadcrumbs";
import { getMuiButton, getMuiButtonDefaultProps } from "./button";
import { getMuiButtonGroup, getMuiButtonGroupDefaultProps } from "./button-group";
import { getMuiCheckbox, getMuiCheckboxDefaultProps } from "./checkbox";
import { getMuiChip, getMuiChipDefaultProps } from "./chip";
import { COLOR_ERROR, COLOR_PRIMARY_BLACK, COLOR_PRIMARY_WHITE } from "./colors";
import { getMuiContainer, getMuiContainerDefaultProps } from "./container";
import { getMuiDrawerDefaultProps } from "./drawer";
import { getMuiFormHelperText } from "./form-helper-text";
import { getMuiGrid, breakpointOptions, getMuiGridDefaultProps } from "./grid";
import { getMuiIconButton, getMuiIconButtonDefaultProps } from "./icon-button";
import { getMuiInputBase } from "./input-base";
import { getMuiInputLabel, getMuiInputLabelDefaultProps } from "./input-label";
import { getMuiLinkDefaultProps, getMuiLinkOverride } from "./link";
import { getMenuItemButton, getMuiMenuDefaultProps } from "./menu";
import { getMuiOutlinedInput } from "./outlined-input";
import { getMuiPopover } from "./popover";
import { getMuiRadio, getMuiRadioDefaultProps } from "./radio";
import { getMuiSelect, getMuiSelectDefaultProps } from "./select";
import { getMuiSwitch, getMuiSwitchDefaultProps } from "./switch";
import {
  getMuiTabs,
  getMuiTab,
  getMuiTabsDefaultProps,
  getMuiTabDefaultProps,
} from "./tabs";
import { getMuiTextFieldDefaultProps } from "./text-field";
import { getMuiToolbar, getMuiToolbarDefaultProps } from "./toolbar";
import { getMuiTooltip } from "./tooltip";
import { getMuiTypographyDefaultProps, getTypographyOptions } from "./typography";

let theme: Theme;

export const getMuiTheme = () => {
  if (theme) {
    return theme;
  }
  theme = createTheme(
    adaptV4Theme({
      palette: {
        error: {
          main: COLOR_ERROR,
        },
        primary: {
          main: COLOR_PRIMARY_BLACK,
        },
        background: {
          default: COLOR_PRIMARY_WHITE,
        },
      },
      breakpoints: breakpointOptions,
    }),
  );

  theme = createTheme(
    adaptV4Theme({
      ...theme,
      typography: getTypographyOptions(theme),
    }),
  );

  theme = createTheme(
    adaptV4Theme({
      ...theme,
      props: {
        MuiLink: getMuiLinkDefaultProps(),
        MuiBackdrop: getMuiBackdropDefaultProps(),
        MuiBreadcrumbs: getMuiBreadcrumbsDefaultProps(),
        MuiButtonBase: { disableRipple: true },
        MuiButton: getMuiButtonDefaultProps(),
        MuiIconButton: getMuiIconButtonDefaultProps(),
        MuiButtonGroup: getMuiButtonGroupDefaultProps(),
        MuiInputLabel: getMuiInputLabelDefaultProps(),
        MuiSelect: getMuiSelectDefaultProps(),
        MuiTextField: getMuiTextFieldDefaultProps(),
        MuiAccordion: getMuiAccordionDefaultProps(),
        MuiAccordionSummary: getMuiAccordionSummaryDefaultProps(),
        MuiAccordionDetails: getMuiAccordionDetailsDefaultProps(),
        MuiTab: getMuiTabDefaultProps(),
        MuiTabs: getMuiTabsDefaultProps(),
        MuiContainer: getMuiContainerDefaultProps(),
        MuiGrid: getMuiGridDefaultProps(),
        MuiMenu: getMuiMenuDefaultProps(),
        MuiCheckbox: getMuiCheckboxDefaultProps(),
        MuiRadio: getMuiRadioDefaultProps(),
        MuiSwitch: getMuiSwitchDefaultProps(),
        MuiChip: getMuiChipDefaultProps(),
        MuiTypography: getMuiTypographyDefaultProps(),
        MuiAppBar: getMuiAppBarDefaultProps(),
        MuiToolbar: getMuiToolbarDefaultProps(),
        MuiDrawer: getMuiDrawerDefaultProps(),
      },
      overrides: {
        MuiLink: getMuiLinkOverride(theme),
        MuiBackdrop: getMuiBackdrop(),
        MuiBreadcrumbs: getMuiBreadcrumbs(theme),
        MuiButton: getMuiButton(theme),
        MuiIconButton: getMuiIconButton(theme),
        MuiButtonGroup: getMuiButtonGroup(),
        MuiInputBase: getMuiInputBase(),
        MuiOutlinedInput: getMuiOutlinedInput(theme),
        MuiInputLabel: getMuiInputLabel(theme),
        MuiFormHelperText: getMuiFormHelperText(theme),
        MuiSelect: getMuiSelect(),
        MuiContainer: getMuiContainer(theme),
        MuiGrid: getMuiGrid(theme),
        MuiAccordion: getMuiAccordion(),
        MuiAccordionSummary: getMuiAccordionSummary(theme),
        MuiAccordionDetails: getMuiAccordionDetails(theme),
        MuiTabs: getMuiTabs(theme),
        MuiTab: getMuiTab(theme),
        MuiMenuItem: getMenuItemButton(),
        MuiCheckbox: getMuiCheckbox(theme),
        MuiRadio: getMuiRadio(theme),
        MuiSwitch: getMuiSwitch(theme),
        MuiChip: getMuiChip(theme),
        MuiPopover: getMuiPopover(theme),
        MuiAppBar: getMuiAppBar(),
        MuiToolbar: getMuiToolbar(theme),
        MuiTooltip: getMuiTooltip(theme),
        MuiCssBaseline: {
          body: {
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.02em",
          },
        },
      },
    }),
  );
  return theme;
};
