import { Theme, ComponentsOverrides } from "@mui/material/styles";
import { TabProps } from "@mui/material/Tab/Tab.d";
import { TabsProps } from "@mui/material/Tabs/Tabs.d";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import * as colors from "~/theme/colors";

import { ARIAL_BLACK_FONT_FAMILY } from "../utils/font.constants";

export const getMuiTabs = (theme: Theme): ComponentsOverrides["MuiTabs"] => {
  return {
    root: {
      boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_GREYSCALE_BLACK_8}`,
    },
    indicator: {
      display: "none",
      backgroundColor: colors.COLOR_PRIMARY_BLACK,
    },
  };
};

export const getMuiTab = (theme: Theme): ComponentsOverrides["MuiTab"] => {
  return {
    root: {
      whiteSpace: "nowrap",
      position: "relative",
      padding: theme.spacing(1.5, 2),
      minWidth: "unset",
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular as number,
      lineHeight: "24px",
      letterSpacing: "0.03em",
      [theme.breakpoints.up("sm")]: { minWidth: "unset" },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2),
        fontSize: 18,
      },
      "&:hover": {
        boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_PRIMARY_BLACK}`,
      },
      "&:active": {
        color: colors.COLOR_PRIMARY_BLACK,
        boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_PRIMARY_BLACK}`,
      },
      "&:focus": {
        boxShadow: `inset 0 0 0 2px ${colors.COLOR_ADDITIONAL_DODGER_BLUE}`,
      },
      "&.Mui-selected": {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        color: colors.COLOR_PRIMARY_BLACK,
        fontWeight: theme.typography.fontWeightBold,
        boxShadow: `inset 0 -2px 0 0 ${colors.COLOR_PRIMARY_BLACK}`,
        "& .MuiTab-wrapper": { marginTop: -2 },
      },
      "&:disabled": {
        color: colors.COLOR_GREYSCALE_BLACK_54,
      },
    },
    textColorInherit: {
      color: colors.COLOR_GREYSCALE_BLACK_75,
      opacity: 1,
    },
  };
};

export const getMuiTabsDefaultProps = (): TabsProps => {
  return getTestAutomationProps("tabs") as TabsProps;
};

export const getMuiTabDefaultProps = (): TabProps => {
  return getTestAutomationProps("tab") as TabProps;
};
