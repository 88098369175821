import React from "react";

import { Theme, ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { FontWeights } from "~/theme/typography";
import { getFormatMedia } from "~/theme/utils";

import {
  COLOR_BLACK,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_5,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "../colors";
import { CloseTiny24 } from "../icons/components/close-tiny";

export enum ChipType {
  hashtag = "hashtag",
  plp = "plp",
  search = "search",
  tag = "tag",
  mediaTag = "mediaTag",
}

export const tagFont = {
  desktop: {
    fontWeight: FontWeights.fontWeightMedium,
    fontSize: 12,
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  mobile: {
    fontWeight: FontWeights.fontWeightMedium,
    fontSize: 11,
    lineHeight: "20px",
    letterSpacing: "0.03em",
  },
};

export const getMuiChip = (theme: Theme): ComponentsOverrides["MuiChip"] => {
  const formatMedia = getFormatMedia(theme);

  const colorScheme = {
    "&, &:hover, &:focus": {
      backgroundColor: COLOR_GREYSCALE_BLACK_5,
      color: COLOR_BLACK,
    },
    "&:active": {
      backgroundColor: COLOR_GREYSCALE_BLACK_8,
    },
    "&:hover": {
      color: COLOR_GREYSCALE_BLACK_75,
    },
  };

  const deleteIconStyles = {
    outline: "none",
    border: "none",
    margin: 0,
    marginRight: theme.spacing(-1),
    "&, &:hover, &:active, &:focus, .Mui-disabled": {
      color: "inherit",
    },
    "&:hover": {
      backgroundColor: COLOR_GREYSCALE_BLACK_5,
    },
    "&:active": {
      backgroundColor: COLOR_GREYSCALE_BLACK_8,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      border: "none",
    },
  };

  return {
    root: {
      borderRadius: 0,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),

      ...colorScheme,
      ...theme.typography.body1,

      "&:focus, &:focus-within": {
        outline: `${COLOR_FOCUSED} solid 2px`,
      },

      [`&.${ChipType.plp}`]: {
        ...theme.typography.body2,
        height: 32,
      },

      [`&.${ChipType.search}`]: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        height: 40,
      },

      [`&.${ChipType.hashtag}`]: {
        ...theme.typography.body1,
        height: 36,
      },

      [`&.${ChipType.tag}`]: {
        paddingLeft: theme.spacing(0.75),
        paddingRight: theme.spacing(0.75),
        height: 20,
        ...tagFont.mobile,
        [formatMedia.BREAKPOINT_DESKTOP]: {
          height: 24,
          ...tagFont.desktop,
        },
      },

      [`&.${ChipType.mediaTag}`]: {
        borderRadius: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        height: 20,
        ...tagFont.mobile,
        [formatMedia.BREAKPOINT_DESKTOP]: {
          height: 24,
          ...tagFont.desktop,
        },
      },
    },
    colorPrimary: colorScheme,
    colorSecondary: colorScheme,
    deletable: colorScheme,
    deletableColorPrimary: colorScheme,
    deletableColorSecondary: colorScheme,
    clickable: colorScheme,
    clickableColorPrimary: colorScheme,
    clickableColorSecondary: colorScheme,

    deleteIcon: deleteIconStyles,
    deleteIconColorPrimary: deleteIconStyles,
    deleteIconColorSecondary: deleteIconStyles,
    icon: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0.75),
    },
    label: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
};

export const getMuiChipDefaultProps = (): ComponentsProps["MuiChip"] => {
  return {
    deleteIcon: <CloseTiny24 />,
    ...getTestAutomationProps("chip"),
  };
};
