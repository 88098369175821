import Cookies from "js-cookie";

import { OPTANON_CONSENT_COOKIES } from "~/constants/user-cookies";

import { getCookieCategories } from "./cookie-parser";

/*
 * This function is used to load the OneTrust cookie banner.
 * This is needed when the user is redirected to the page from another page
 * after deleting the OptanonConsent cookie.
 */
export const loadCookieBanner = (): void => {
  const bannerElement = document?.getElementById("onetrust-banner-sdk");
  const shouldBannerShowing =
    !bannerElement || bannerElement?.style?.display === "none";

  if (shouldBannerShowing) {
    const otConsentSdk = document.getElementById("onetrust-consent-sdk");
    if (otConsentSdk) {
      otConsentSdk.remove();
    }
    OneTrust?.Init?.();
    OneTrust?.InitializeBanner?.();
    OneTrust?.LoadBanner?.();
  }
};

/*
 * This function is used to align the category checkboxes with the cookie consent
 * that is stored in the OptanonConsent cookie.
 * This is needed because sometimes the OneTrust cookie banner does not update the checkboxes
 * when the cookie consent is updated.
 */
export const alignCategoryCheckboxWithTerritoryCookie = (): void => {
  const cookieCategories = getCookieCategories(Cookies.get(OPTANON_CONSENT_COOKIES));
  const categoryCheckboxes = document
    ?.getElementById("onetrust-consent-sdk")
    ?.getElementsByClassName(
      "category-switch-handler",
    ) as HTMLCollectionOf<HTMLInputElement>;

  if (categoryCheckboxes?.length) {
    for (let i = 0; i < categoryCheckboxes?.length; i++) {
      const cookieCategoriesIndex = categoryCheckboxes[i]?.dataset?.optanongroupid;

      if (typeof cookieCategoriesIndex !== "string") {
        continue;
      }

      if (cookieCategories[cookieCategoriesIndex]) {
        categoryCheckboxes[i].setAttribute("checked", "checked");
        categoryCheckboxes[i].setAttribute("aria-checked", "true");
        categoryCheckboxes[i].checked = true;
      }

      if (!cookieCategories[cookieCategoriesIndex]) {
        categoryCheckboxes[i].removeAttribute("checked");
        categoryCheckboxes[i].setAttribute("aria-checked", "false");
        categoryCheckboxes[i].checked = false;
      }
    }
  }
};
