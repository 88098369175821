import Cookies from "js-cookie";
import { merge } from "lodash";

import { LOCALES } from "~/constants/i18n";
import {
  OPTANON_ALERT_BOX_CLOSED,
  OPTANON_CONSENT_COOKIES,
} from "~/constants/user-cookies";
import { getCountryCode } from "~/helpers/country/get-country-code";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";

export const STORAGE_KEY = "oneTrustTerritoryCookies";
export const COOKIE_KEYS = [OPTANON_CONSENT_COOKIES, OPTANON_ALERT_BOX_CLOSED];

type TerritoryCookies = Record<string, string> | null;
type TerritoryCookiesStore = Record<string, TerritoryCookies>;

const getOneTrustCookiesToSaveInStore = (): TerritoryCookies | null => {
  const cookiesToSave: Record<string, string> = {};

  for (const cookieKey of COOKIE_KEYS) {
    const cookiesValue = Cookies.get(cookieKey);
    if (typeof cookiesValue === "string") {
      cookiesToSave[cookieKey] = cookiesValue;
    }
  }

  const areRequiredCookiesPresented =
    Object.keys(cookiesToSave).length === COOKIE_KEYS.length;
  return areRequiredCookiesPresented ? cookiesToSave : null;
};

const getCookiesStore = (): TerritoryCookiesStore | null => {
  const json = localStorage.getItem(STORAGE_KEY);
  if (json === null) {
    return null;
  }

  try {
    const store = JSON.parse(json);
    const countries = Object.keys(store).filter((countryCode) => store[countryCode]);
    for (const countryCode of countries) {
      for (const cookieKey of COOKIE_KEYS) {
        if (typeof store[countryCode][cookieKey] !== "string") {
          return null;
        }
      }
    }
    return store;
  } catch {
    return null;
  }
};

const initializeCookiesStore = (country = "GB") => {
  const cookiesToSaveInStore = getOneTrustCookiesToSaveInStore();
  if (cookiesToSaveInStore) {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        [country]: cookiesToSaveInStore,
      }),
    );
  } else {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
  }
};

const updateCookiesStore = (value: TerritoryCookiesStore) => {
  const store = getCookiesStore();
  localStorage.setItem(STORAGE_KEY, JSON.stringify(merge(store, value)));
};

const isFirstLoadAfterTerritoryUpdate = (): boolean => {
  return localStorage.getItem(STORAGE_KEY) === null;
};

const setOneTrustCookies = (
  cookiesFromStore: TerritoryCookies,
  cookieDomain: string | undefined,
): void => {
  for (const cookieKey in cookiesFromStore) {
    Cookies.set(
      cookieKey,
      cookiesFromStore[cookieKey],
      cookieDomain
        ? {
            path: "/",
            domain: cookieDomain,
          }
        : undefined,
    );
  }
};

const clearOneTrustCookies = (cookieDomain: string | undefined) => {
  COOKIE_KEYS.forEach((cookieKey) =>
    Cookies.remove(
      cookieKey,
      cookieDomain
        ? {
            path: "/",
            domain: cookieDomain,
          }
        : undefined,
    ),
  );
};
/*
 * This function is called when the locale changes.
 * It checks if the user has already accepted cookies for the current territory.
 * If yes, it sets the cookies from the store.
 * If the user has accepted cookies for the current territory, but the store is empty,
 * it initializes the store with the current territory cookies.
 * If the user has accepted cookies for the current territory, but the store is not empty,
 * it updates the store with the current territory cookies.
 */
export const processOneTrustTerritoryCookies = (
  locale: LOCALES,
  prevLocale: LOCALES,
): void => {
  const countryCode = getCountryCode(locale);
  const prevCountryCode = getCountryCode(prevLocale);
  const cookiesToSaveInStore = getOneTrustCookiesToSaveInStore();
  const cookieDomain = AzureConfigurator.getConfig(locale)?.cookieDomain;

  if (cookiesToSaveInStore === null && prevCountryCode !== null) {
    updateCookiesStore({
      [prevCountryCode]: null,
    });
  }

  if (!isFirstLoadAfterTerritoryUpdate()) {
    clearOneTrustCookies(cookieDomain);
  }

  const cookiesStore = getCookiesStore();
  if (cookiesStore === null) {
    initializeCookiesStore(countryCode ?? undefined);
    return;
  }

  const countryCookies = countryCode !== null ? cookiesStore[countryCode] : null;

  if (countryCookies) {
    setOneTrustCookies(countryCookies, cookieDomain);
  }
};

/*
 * This function is called when the user accepts cookies.
 * It updates the store with the current territory cookies.
 */
export const handleCookieAcceptance = (locale: LOCALES): void => {
  const countryCode = getCountryCode(locale);
  const cookiesToSaveInStore = getOneTrustCookiesToSaveInStore();
  if (cookiesToSaveInStore && countryCode !== null) {
    updateCookiesStore({
      [countryCode]: cookiesToSaveInStore,
    });
  }
};
