import { Theme, ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getFormatMedia } from "~/theme/utils";

export const getMuiToolbar = (theme: Theme): ComponentsOverrides["MuiToolbar"] => {
  const formatMedia = getFormatMedia(theme);
  return {
    gutters: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },

      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },

      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
  };
};

export const getMuiToolbarDefaultProps = (): ComponentsProps["MuiToolbar"] =>
  getTestAutomationProps("toolbar") as ComponentsProps["MuiToolbar"];
