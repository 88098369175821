import { Theme, ComponentsOverrides } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_75 } from "~/theme/colors";

export const getMuiFormHelperText = (
  theme: Theme,
): ComponentsOverrides["MuiFormHelperText"] => ({
  contained: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    color: COLOR_GREYSCALE_BLACK_75,

    // todo: We need to reuse styles defined in Typography for body2.
    fontFamily: "Arial",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.02em",
  },
});
