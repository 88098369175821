import { Theme, ComponentsOverrides } from "@mui/material/styles";

import { COLOR_BLACK, COLOR_GREYSCALE_BLACK_15, COLOR_WHITE } from "~/theme/colors";

export enum TooltipType {
  darkTip = "darkTip",
  lightTip = "lightTip",
}

export const getMuiTooltip = (theme: Theme): ComponentsOverrides["MuiTooltip"] => {
  return {
    tooltip: {
      borderRadius: 0,
      lineHeight: "1.9em",
      [`&.${TooltipType.lightTip}`]: {
        backgroundColor: COLOR_WHITE,
        color: COLOR_BLACK,
        boxShadow: `0px ${theme.spacing(0.5)} ${theme.spacing(
          2,
        )} ${COLOR_GREYSCALE_BLACK_15}`,
      },
      [`&.${TooltipType.darkTip}`]: {
        backgroundColor: COLOR_BLACK,
        color: COLOR_WHITE,
        boxShadow: "none",
      },
    },
    arrow: {
      [`&.${TooltipType.lightTip}`]: {
        "&::before": {
          color: theme.palette.common.white,
        },
      },
      [`&.${TooltipType.darkTip}`]: {
        "&::before": {
          color: COLOR_BLACK,
        },
      },
    },
  };
};
