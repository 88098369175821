import { useMemo } from "react";

import { LOCALES } from "~/constants/i18n";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";

import { getMsalInstance } from "./get-msal-instance";

export const useMsalInstance = (
  locale: LOCALES,
): ReturnType<typeof getMsalInstance> => {
  const { appConfig } = useAzureConfigurator(locale);

  return useMemo(
    () => getMsalInstance(locale),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, appConfig],
  );
};
