"use client";
import React, { FunctionComponent } from "react";

import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import { cloneDeep } from "lodash";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { AuthProvider } from "~/context/auth/provider";
import { RouterHistoryProvider } from "~/context/router-history/provider";
import { ShoppingListProvider } from "~/context/shopping-list/provider";
import { useOneTrust } from "~/hooks/use-one-trust";
import { CLIENT_STORAGE_NAME } from "~/services/config-storage/constant";
import { useMsalInstance } from "~/services/msal/use-msal-instance";
import { getMuiTheme } from "~/theme/theme";

import { CorrelationIdContextProvider } from "src/context/correlation-id/provider";

import { CsrRootLayoutProps } from "./types";

export const CsrRootLayout: FunctionComponent<CsrRootLayoutProps> = ({
  children,
  correlationId,
  azureConfig,
  publicEnvStorageData,
}) => {
  const { locale } = useParams<GlobalPathParams>();

  // eslint-disable-next-line no-restricted-globals
  if (typeof window !== "undefined" && !window[CLIENT_STORAGE_NAME]) {
    // eslint-disable-next-line no-restricted-globals
    window[CLIENT_STORAGE_NAME] = cloneDeep(azureConfig);
    const processEnv = publicEnvStorageData ?? null;
    // eslint-disable-next-line no-process-env
    process.env = { ...process.env, ...processEnv, IS_READY: "true" };
  }

  const msalInstance = useMsalInstance(locale);
  useOneTrust(locale);

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MsalProvider instance={msalInstance}>
        <CorrelationIdContextProvider correlationId={correlationId}>
          <RouterHistoryProvider>
            <AuthProvider>
              <ShoppingListProvider>{children}</ShoppingListProvider>
            </AuthProvider>
          </RouterHistoryProvider>
        </CorrelationIdContextProvider>
      </MsalProvider>
    </ThemeProvider>
  );
};
