import { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { Theme, ComponentsOverrides } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";

export const getMuiAccordionDetails = (
  theme: Theme,
): ComponentsOverrides["MuiAccordionDetails"] => {
  return {
    root: {
      padding: 0,
      paddingBottom: theme.spacing(2),
      flexDirection: "column",
    },
  };
};

export const getMuiAccordionDetailsDefaultProps = (): AccordionDetailsProps =>
  getTestAutomationProps("details") as AccordionDetailsProps;
