import { Theme, ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

export const getMuiContainer = (
  theme: Theme,
): ComponentsOverrides["MuiContainer"] => {
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: 1920,
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
  };
};

export const getMuiContainerDefaultProps = (): ComponentsProps["MuiContainer"] => {
  return { maxWidth: false };
};
