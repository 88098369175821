import { ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

export const getMuiMenuDefaultProps = (): ComponentsProps["MuiMenu"] => {
  return {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };
};

export const getMenuItemButton = (): ComponentsOverrides["MuiMenuItem"] => {
  return {
    root: {
      height: 40,
    },
  };
};
