import { Theme, ComponentsOverrides } from "@mui/material/styles";

import { COLOR_GREYSCALE_BLACK_15 } from "~/theme/colors";

export const getMuiPopover = (theme: Theme): ComponentsOverrides["MuiPopover"] => {
  return {
    root: {
      zIndex: 999,
    },
    paper: {
      borderRadius: 0,
      boxShadow: `${theme.spacing(0, 1, 3)} ${COLOR_GREYSCALE_BLACK_15}`,
    },
  };
};
