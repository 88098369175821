import { BreadcrumbsProps } from "@mui/material/Breadcrumbs/Breadcrumbs.d";
import { Theme, ComponentsOverrides } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import {
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_GREYSCALE_BLACK_8,
} from "~/theme/colors";
import { FontWeights } from "~/theme/typography";

export const breadcrumbsTypography = {
  height: 24,
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: FontWeights.fontWeightRegular,
  lineHeight: "24px",
  letterSpacing: "0.02em",
};

export const getMuiBreadcrumbs = (
  theme: Theme,
): ComponentsOverrides["MuiBreadcrumbs"] => {
  return {
    li: {
      "& > *:focus:before": {
        display: "none",
      },
      "& > *, & > *:hover, & > *:active, & > *:focus": {
        padding: 0,
        color: COLOR_BLACK,
        margin: theme.spacing(1, 0),
        backgroundColor: "transparent",
        ...breadcrumbsTypography,
      },
      "& > *:hover": {
        color: COLOR_GREYSCALE_BLACK_75,
        textDecoration: "underline",
      },
      "& > *:active": {
        color: COLOR_BLACK,
        textDecoration: "underline",
      },
      "&:last-child > *": {
        textDecoration: "none",
      },
    },
    separator: {
      ...breadcrumbsTypography,
      textAlign: "center",
      margin: theme.spacing(0, 1),
      width: 4,
    },
    root: {
      // this is commented due to error message after the MUI update
      // "&.withBack $li:first-child + $separator": {
      "&.withBack .MuiBreadcrumbs-li::first-of-type + .Mui-separator": {
        position: "relative",
        color: "transparent",
        width: 17,
        "&:after": {
          content: "''",
          position: "absolute",
          top: 4,
          left: 0,
          bottom: 4,
          width: 9,
          borderRightWidth: 1,
          borderRightStyle: "solid",
          borderRightColor: COLOR_GREYSCALE_BLACK_8,
        },
      },
    },
  };
};

export const getMuiBreadcrumbsDefaultProps = (): BreadcrumbsProps => {
  return {
    separator: "/",
    ...getTestAutomationProps("breadcrumbs"),
  } as BreadcrumbsProps;
};
