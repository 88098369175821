import { gql } from "@apollo/client";

import { GLOBAL_LAYOUT_FIELDS } from "~/graphql/queries/getGlobalLayout";
import { GET_GLOBAL_NAVIGATION_FIELDS } from "~/graphql/queries/getGlobalNavigation";

export const FRAGMENT_SHOPPING_LIST_PRODUCTS = gql`
  fragment shoppingListProducts on ShoppingList {
    id
    anonymousId
    products {
      id
      addedAt
      name
      productSlug
      sizeIsSelected
      sku
      productCode
      skuCode
      styleCode
      displayColor
      startDate
      endDate
      noLongerAvailable
      displayCountryOrigin
      displaySize
      skuDimensions
      sizeId
      primarkCares
      clickAndCollect
      clickAndCollectExclusive
      images {
        url
        label
        height
        width
      }
      price
      variants {
        sku
        productCode
        skuCode
        displaySize
        sizeId
        skuDimensions
        price
        images {
          url
          label
        }
      }
      inventory(locale: $locale, storeId: $storeId) {
        available
        clickAndCollectStore
        clickAndCollectStock
        clickAndCollectAvailableQuantity
        salesChannel
      }
      pricePerUnit
      measureOfPrice
      uomOfPrice
    }
  }
`;

export const FRAGMENT_STORE_DETAILS_BY_ID = gql`
  fragment storeDetailsById on Store {
    id
    trial
    googlePlaceId
    name
    geomodifier
    countryCode
    clickAndCollectStore
    address {
      line1
      line2
      city
      postalCode
      countryCode
      region
    }
    hours {
      monday {
        openIntervals {
          start
          end
        }
        isClosed
        date
      }
      tuesday {
        openIntervals {
          end
          start
        }
        isClosed
        date
      }
      wednesday {
        openIntervals {
          end
          start
        }
        isClosed
        date
      }
      thursday {
        openIntervals {
          end
          start
        }
        isClosed
        date
      }
      friday {
        openIntervals {
          end
          start
        }
        isClosed
        date
      }
      saturday {
        openIntervals {
          end
          start
        }
        isClosed
        date
      }
      sunday {
        openIntervals {
          end
          start
        }
        isClosed
        date
      }
    }
    holidayHours {
      openIntervals {
        end
        start
      }
      isClosed
      date
    }
    description
    departments {
      departmentName
      departmentFloor
      departmentURL
      fontColour
      departmentImage {
        url
        sourceUrl
        alternateText
      }
    }
    paymentOptions
    phoneNumber
    phoneNumberFormattedNational
    phoneNumberFormattedInternational
    additionalHoursText
    parkingAddress
    facilities
    faqTitle
    faqUrl
    helpTitle
    helpLink
    timeZoneUtcOffset
    timezone
    displayCoordinate {
      latitude
      longitude
    }
    routableCoordinate {
      latitude
      longitude
    }
    walkableCoordinate {
      latitude
      longitude
    }
    metaDescription
    metaTitle
    services {
      id
      name
      address {
        line1
        line2
        city
        postalCode
        countryCode
        region
      }
      hours {
        monday {
          openIntervals {
            end
            start
          }
          date
          isClosed
        }
        tuesday {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
        wednesday {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
        thursday {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
        friday {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
        saturday {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
        sunday {
          openIntervals {
            end
            start
          }
          isClosed
          date
        }
      }
      url
      image {
        url
        alt
      }
      bookingCTA {
        label
        link
        linkType
      }
    }
    distance {
      from {
        latitude
        longitude
      }
      distanceMiles
      distanceKilometers
    }
    storeLimits {
      minOrderPrice
      maxOrderPrice
      maxLineItemQuantity
    }
  }
`;

export const GET_SHOPPING_LIST_PAGE_BY_ID = gql`
  fragment shoppingListPageById on ShoppingListPage {
    component
    props {
      shoppingList(
        locale: $locale
        currencyCode: $currencyCode
        shoppingListId: $shoppingListId
      ) {
        ...shoppingListProducts
      }
      sharedShoppingList {
        notSelectedText
        sizeText
        theListIsEmptyText
      }
      translations {
        pageTitle
        signInButtonName
        signInRegisterBlockTextField1
        registerButtonName
        signInRegisterBlockTextField2
        startSavingBySelectingTheHeartIconTextField
        continueBrowsingButtonName
        itemAddedToYourShoppingListTextField
        selectStoreButtonName
        theProductHasBeenRemovedTextField
        undoButtonName
        progressBarText
        loadMoreButtonName
        storeDetailsButtonName
        changeStoreButtonName
        selectSizeTextField
        sizeIsNeededForStockStatusTextField
        sendButtonName
        linkCopiedTextField
        myAccountBreadcrumbsText
      }
      slidingPanel {
        myStore {
          myStoreTitle
          itemsAreMovingFastTextField
          selectStoreButtonName
          alreadyHaveAccountTextField
          signInTextName
        }
        sendYourShoppingList {
          sendYourShoppingListTitle
          shareLinkToYourShoppingListTextField
          copyLinkTextField
        }
      }
      stockStatuses {
        inStock
        stockIsRunning
        soldOut
        notAvailable
        notLonger
        notSoldInStore
      }
      storeDetails {
        descriptionSubtitles {
          addressText
          hoursText
          holidayHoursText
          facilitiesText
        }
        hoursDescription {
          weekdays {
            mondayText
            tuesdayText
            wednesdayText
            thursdayText
            fridayText
            saturdayText
            sundayText
          }
          openNowText
          closedNowText
        }
        upcomingHolidaysText
        closedDescriptionText
        changeStoreMobileButton
        changeStoreDesktopButton
        moreStoreDetailsButton
      }
      storeDetailsById(locale: $locale, storeId: $storeId) {
        ...storeDetailsById
      }
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS}
  ${FRAGMENT_STORE_DETAILS_BY_ID}
`;

export const SHOPPINGLIST_BY_ID_PAGE_WITH_GLOBAL_LAYOUT = gql`
  query ShoppingListPageByIdMixedQuery(
    $locale: String!
    $pageSlug: String
    $storeId: String
    $currencyCode: String!
    $shoppingListId: String
  ) {
    layout: globalLayout(locale: $locale, pageSlug: $pageSlug) {
      ...globalLayoutFields
    }
    navigation: globalNavigation(locale: $locale) {
      ...globalNavigationFields
    }
    content: shoppingListPage(locale: $locale) {
      ...shoppingListPageById
    }
  }
  ${GLOBAL_LAYOUT_FIELDS}
  ${GET_GLOBAL_NAVIGATION_FIELDS}
  ${GET_SHOPPING_LIST_PAGE_BY_ID}
`;

export const SHOPPINGLIST_BY_ID_PAGE = gql`
  query ShoppingListPageByIdQuery(
    $locale: String!
    $storeId: String
    $currencyCode: String!
    $shoppingListId: String
  ) {
    content: shoppingListPage(locale: $locale) {
      ...shoppingListPageById
    }
  }
  ${GET_SHOPPING_LIST_PAGE_BY_ID}
`;
