import { LinkProps } from "@mui/material/Link/Link.d";
import { Theme, ComponentsOverrides } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";

export const getMuiLinkDefaultProps = (): LinkProps => {
  return {
    underline: "always",
    ...getTestAutomationProps("link"),
  };
};

export const getMuiLinkOverride = (theme: Theme): ComponentsOverrides["MuiLink"] => {
  return {
    root: {
      cursor: "pointer",
      color: "inherit",
      fontWeight: "inherit",
      fontStyle: "inherit",
      textDecorationColor: "inherit",
    },
  };
};
