import * as msal from "@azure/msal-browser";

import { LOCALES } from "~/constants/i18n";
import { Logger } from "~/utils/logger";

import { getB2cPolicies } from "./get-b2c-policies";
import { getClientId } from "./get-client-id";

export const getMsalInstance = (locale: LOCALES): msal.PublicClientApplication => {
  const AZURE_AD_B2C_CLIENT_ID = getClientId(locale);

  const b2cPolicies = getB2cPolicies(locale);

  const config: msal.Configuration = {
    auth: {
      clientId: AZURE_AD_B2C_CLIENT_ID ?? "",
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      navigateToLoginRequestUrl: false, //Boolean indicating whether to navigate to the original request URL after the auth server navigates to the redirect URL.
    },
    cache: {
      storeAuthStateInCookie: false,
      cacheLocation: "localStorage",
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: msal.LogLevel,
          message: string,
          containsPii: boolean,
        ): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              Logger.getLogger().error(message);
              return;
            case msal.LogLevel.Info:
              Logger.getLogger().info(message);
              return;
            case msal.LogLevel.Verbose:
              Logger.getLogger().debug(message);
              return;
            case msal.LogLevel.Warning:
              Logger.getLogger().warn(message);
              return;
          }
        },
        piiLoggingEnabled: false,
      },
    },
  };

  return new msal.PublicClientApplication(config);
};
