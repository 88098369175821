import { useCallback, useEffect, useRef } from "react";

import { usePathname } from "next/navigation";

import { LOCALES } from "~/constants/i18n";
import { PREVIOUS_CURRENT_LOCALES } from "~/constants/local-storage";
import { awaitGlobalsInitialization } from "~/helpers/await-globals-initialization";
import { getCountryLangFromLocale } from "~/helpers/locales";
import {
  alignCategoryCheckboxWithTerritoryCookie,
  loadCookieBanner,
} from "~/helpers/one-trust/cookie-banner";
import {
  handleCookieAcceptance,
  processOneTrustTerritoryCookies,
} from "~/helpers/one-trust/territory-cookies-manager";
import { Evergage } from "~/lib/salesforce/evergage";
import { Logger } from "~/utils/logger";

export const useOneTrust = (locale: LOCALES) => {
  const localeRef = useRef(locale);
  const pathname = usePathname();
  const logger = Logger.getLogger();

  useEffect(() => {
    localeRef.current = locale;
  }, [locale]);

  useEffect(() => {
    const prevLocale =
      (localStorage.getItem(PREVIOUS_CURRENT_LOCALES)?.split(",")[0] as LOCALES) ||
      locale;

    if (locale !== prevLocale) {
      processOneTrustTerritoryCookies(locale, prevLocale);
    }
    awaitGlobalsInitialization({ windowVar: "OneTrust" })
      .then(() => {
        setTimeout(() => {
          if (locale !== prevLocale) {
            const lang = getCountryLangFromLocale(locale);
            if (typeof OneTrust !== "undefined") {
              if (typeof OneTrust.changeLanguage === "function") {
                OneTrust?.changeLanguage(lang);
              }
              if (
                typeof OneTrust.IsAlertBoxClosed === "function" &&
                OneTrust.IsAlertBoxClosed()
              ) {
                const bannerElement =
                  document?.getElementById("onetrust-banner-sdk");
                const fadeElement = document
                  ?.getElementsByClassName("onetrust-pc-dark-filter")
                  ?.item(0);

                if (bannerElement) {
                  bannerElement.remove();
                }

                if (fadeElement) {
                  fadeElement.setAttribute("style", "display: none");
                }

                if (typeof OneTrust.Init === "function") {
                  OneTrust.Init();
                }
                alignCategoryCheckboxWithTerritoryCookie();
              } else {
                loadCookieBanner();
              }
            }
          }
        }, 1000);
      })
      .catch((reason) => {
        logger.error(reason);
      });
  }, [locale, logger]);

  const listener = useCallback(() => {
    handleCookieAcceptance(localeRef.current);
  }, []);

  useEffect(() => {
    awaitGlobalsInitialization({ windowVar: "OneTrust" })
      .then(() => {
        // eslint-disable-next-line no-restricted-globals
        window?.addEventListener("consent.onetrust", listener);
      })
      .catch((reason) => {
        logger.error(reason);
      });

    return () => {
      // eslint-disable-next-line no-restricted-globals
      window?.removeEventListener("consent.onetrust", listener);
    };
  }, [listener, logger]);

  const initEvergageCallback = useCallback(() => {
    Evergage.init(locale);
  }, [locale]);

  useEffect(() => {
    awaitGlobalsInitialization({ windowVar: "OneTrust" })
      .then(() => {
        if (OneTrust?.IsAlertBoxClosed?.()) {
          initEvergageCallback();
        } else {
          // eslint-disable-next-line no-restricted-globals
          window?.addEventListener("consent.onetrust", initEvergageCallback);
        }
      })
      .catch((reason) => {
        logger.error(reason);
      });

    return () => {
      // eslint-disable-next-line no-restricted-globals
      window?.removeEventListener("consent.onetrust", initEvergageCallback);
    };
  }, [pathname, initEvergageCallback, logger]);
};
