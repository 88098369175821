import { ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";

export const getMuiTextFieldDefaultProps = (): ComponentsProps["MuiTextField"] => {
  return {
    variant: "outlined",
    SelectProps: {
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      },
    },
    ...getTestAutomationProps("control"),
  };
};
