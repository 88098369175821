import { gql } from "@apollo/client";

import { GET_SHOPPING_BAG_PRODUCT_FIELDS } from "~/graphql/queries/shoppingBagQueries";

import { FRAGMENT_SHOPPING_LIST_PRODUCTS } from "../getShoppingListById";

export const FRAGMENT_SHOPPING_LIST_PRODUCTS_WITHOUT_INVENTORY = gql`
  fragment shoppingListProductFieldsWithoutInventory on ShoppingList {
    id
    anonymousId
    products {
      id
      addedAt
      name
      productSlug
      sizeIsSelected
      sku
      productCode
      skuCode
      styleCode
      displayColor
      startDate
      endDate
      noLongerAvailable
      displayCountryOrigin
      displaySize
      skuDimensions
      sizeId
      primarkCares
      clickAndCollect
      clickAndCollectExclusive
      images {
        url
        label
        height
        width
      }
      price
      variants {
        sku
        productCode
        skuCode
        displaySize
        sizeId
        skuDimensions
        price
        images {
          url
          label
        }
      }
    }
  }
`;

export const GET_USER_SHOPPING_LIST_SKU_ONLY = gql`
  query ShoppingListSkuOnly(
    $locale: String!
    $currencyCode: String!
    $shoppingListId: String
  ) {
    shoppingList(
      locale: $locale
      currencyCode: $currencyCode
      shoppingListId: $shoppingListId
    ) {
      ...shoppingListProductFieldsWithoutInventory
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS_WITHOUT_INVENTORY}
`;

export const GET_USER_SHOPPING_LIST = gql`
  query ShoppingList(
    $locale: String!
    $currencyCode: String!
    $shoppingListId: String
    $storeId: String
  ) {
    shoppingList(
      locale: $locale
      currencyCode: $currencyCode
      shoppingListId: $shoppingListId
    ) {
      ...shoppingListProducts
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS}
`;

export const ADD_PRODUCT_TO_SHOPPING_LIST = gql`
  mutation AddShoppingListItem(
    $locale: String!
    $currencyCode: String!
    $sku: String!
    $sizeIsSelected: Boolean!
    $shoppingListId: String
    $addedAt: String
  ) {
    addShoppingListItem(
      locale: $locale
      currencyCode: $currencyCode
      sku: $sku
      sizeIsSelected: $sizeIsSelected
      shoppingListId: $shoppingListId
      addedAt: $addedAt
    ) {
      ...shoppingListProductFieldsWithoutInventory
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS_WITHOUT_INVENTORY}
`;

export const UNDO_REMOVE_ITEM_FROM_SHOPPING_LIST = gql`
  mutation UndoRemoveItemFromShoppingList(
    $locale: String!
    $currencyCode: String!
    $sku: String!
    $sizeIsSelected: Boolean!
    $shoppingListId: String
    $addedAt: String
    $storeId: String
  ) {
    addShoppingListItem(
      locale: $locale
      currencyCode: $currencyCode
      sku: $sku
      sizeIsSelected: $sizeIsSelected
      shoppingListId: $shoppingListId
      addedAt: $addedAt
    ) {
      ...shoppingListProducts
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS}
`;

export const REMOVE_ITEM_FROM_SHOPPING_LIST = gql`
  mutation RemoveShoppingListItem(
    $shoppingListId: String!
    $removableItemId: String!
    $locale: String!
    $currencyCode: String!
    $sizeIsSelected: Boolean!
  ) {
    removeShoppingListItem(
      shoppingListId: $shoppingListId
      removableItemId: $removableItemId
      locale: $locale
      currencyCode: $currencyCode
      sizeIsSelected: $sizeIsSelected
    ) {
      ...shoppingListProductFieldsWithoutInventory
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS_WITHOUT_INVENTORY}
`;

export const REPLACE_ITEM_IN_SHOPPING_LIST = gql`
  mutation ReplaceShoppingListItem(
    $shoppingListId: String!
    $removableItemId: String!
    $sku: String!
    $locale: String!
    $currencyCode: String!
    $storeId: String
  ) {
    replaceShoppingListItem(
      shoppingListId: $shoppingListId
      removableItemId: $removableItemId
      sku: $sku
      locale: $locale
      currencyCode: $currencyCode
    ) {
      ...shoppingListProducts
    }
  }
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS}
`;

export const MOVE_ITEM_FROM_SHOPPING_LIST_TO_SHOPPING_BAG = gql`
  mutation MoveItemFromShoppingListToShoppingBag(
    $shoppingListId: String!
    $shoppingBagId: String!
    $removableItemId: String!
    $sku: String!
    $locale: String!
    $currencyCode: String!
    $quantity: Int!
    $collectionStoreId: String!
    $storeId: String
    $sizeIsSelected: Boolean!
  ) {
    moveItemFromShoppingListToShoppingBag(
      shoppingListId: $shoppingListId
      shoppingBagId: $shoppingBagId
      removableItemId: $removableItemId
      sku: $sku
      locale: $locale
      currencyCode: $currencyCode
      quantity: $quantity
      collectionStoreId: $collectionStoreId
      sizeIsSelected: $sizeIsSelected
    ) {
      shoppingBag {
        id
        anonymousId
        totalPrice
        products {
          ...shoppingBagProductFields
        }
        storeLimits(locale: $locale) {
          maxLineItemQuantity
        }
      }
      shoppingList {
        ...shoppingListProducts
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
  ${FRAGMENT_SHOPPING_LIST_PRODUCTS}
`;
