import { ComponentsOverrides } from "@mui/material/styles";

import { COLOR_BLACK } from "~/theme/colors";

export const getMuiInputBase = (): ComponentsOverrides["MuiInputBase"] => {
  return {
    root: {
      height: 48,
      color: COLOR_BLACK,
    },
  };
};
