import { ComponentsOverrides, ComponentsProps } from "@mui/material/styles";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { COLOR_BLACK, COLOR_WHITE } from "~/theme/colors";

export const getMuiAppBar = (): ComponentsOverrides["MuiAppBar"] => {
  const colorScheme = {
    backgroundColor: COLOR_WHITE,
    color: COLOR_BLACK,
  };

  return {
    root: colorScheme,
    colorPrimary: colorScheme,
    colorSecondary: colorScheme,
    colorDefault: colorScheme,
  };
};

export const getMuiAppBarDefaultProps = (): ComponentsProps["MuiAppBar"] =>
  getTestAutomationProps("app-bar") as ComponentsProps["MuiAppBar"];
