import { MyAccountPage } from "~/bff/types/MyAccountPage";

export enum STATES {
  ACCOUNT,
  PROFILE,
  ORDER_HISTORY,
  PAYMENT_CARDS,
  BILLING_ADDRESSES,
  ORDER_DETAILS,
}

export type AccountPageProps = MyAccountPage["props"];

export interface AuthenticatedTemplateProps {
  children: React.ReactNode;  
}
