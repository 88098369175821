import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/animate.css/animate.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["CsrRootLayout"] */ "/app/src/components/csr-root-layout/component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.css");
