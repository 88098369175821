/*
To find more about size codes, please, see - https://team-1626860771808.atlassian.net/wiki/spaces/OSCAR/pages/125141022/VD6+-+Size
*/
const oneSizeCodesArray = [
  "9111",
  "19111",
  "1402",
  "1403",
  "1404",
  "1405",
  "1406",
  "1407",
  "1408",
  "1409",
  "1410",
  "1411",
  "1412",
  "1413",
  "1414",
  "1415",
  "1416",
  "1417",
  "1418",
  "1419",
  "1420",
  "1421",
  "1422",
  "1423",
  "1424",
  "1425",
  "1426",
];

export const oneSizeCodesSet = new Set(oneSizeCodesArray);
